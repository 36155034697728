.dialog {
  :global {
    .MuiDialog-container {
      align-items: flex-start;
      margin-top: 100px;

      @media screen and (max-width: 1000px) {
        align-items: center;
        margin-top: 0;
      }
    }

    .MuiPaper-root {
      border-radius: 18px;
      width: 100%;
      max-width: 510px;
      margin: 15px;
      max-height: calc(100% - 164px);
      background: transparent;

      @media screen and (max-width: 1000px) {
        max-height: calc(100% - 64px);
      }
    }
  }

  p {
    margin: 0;
  }
}

.header {
  position: relative;
  background: #1c2230;

  display: flex;
  z-index: 3;
  min-height: 56px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    min-height: 58px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: #630e17;
    display: block;
  }
}

.cardContent {
  background-color: #ffffff;
  padding-top: 30px;
  position: relative;
  z-index: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .txID {
    margin: 10px auto 35px;
  }
}

.dark {
  .cardContent {
    color: #fff;
    background: #302e2d;
  }

  .buttonContainer{
    background-color: #1c2230;
  }
}

.headerTitle {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #ffffff;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: transparent;
  outline: none;
  border: 0;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.buttonContainer {
  margin-top: auto;
  background: #F1F3F9;
  padding: 21px;
  display: flex;
  flex-direction: column;

  .doneButton {
    border-radius: 100px;
    width: 100%;
    margin: 0 auto;
    min-height: 44px;
    max-width: 294px;
    padding: 5px 16px;
    color: #ffffff;
    text-decoration: none;
    text-transform: none;
    font-weight: 700;
    font-size: 19px;

    &:not(:last-child) {
      margin-bottom: 27px;
    }
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
}

.error {
  text-align: center;
  margin-bottom: 16px;
  color: #630e17;
}

.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin: 6px auto 8px;
  width: 131px;
  height: 131px;
  background: #F0F1F6;
  border: 12px solid #F9FAFD;

  img {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: '';
    width: 65px;
    height: 65px;
    background-color: #43AF64;
    border-radius: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.withdrawnValue {
  font-size: 21px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
  color: #630e17;
}

.status {
  font-size: 21px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
}
