.accountContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 992px) {
    justify-content: flex-end;
  }
}
.hasMeta {
  display: flex;
}
.drawerBtn {
  display: none;
}
.drawerBtn2 {
  display: none;
}
.mainDrawer {
  width: 280px;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.drawerItem {
  margin-left: 0 !important;
  filter: none !important;
  width: 100% !important;
  border-radius: 0 !important;
  height: 70px !important;
  justify-content: center !important;
  border-bottom: 1px solid !important;
  margin-top: 0 !important;
}
@media screen and (max-width: 850px) {
  .bscBtns {
    display: none !important;
  }
  .drawerBtn {
    display: block !important;
  }
}
@media screen and (max-width: 767px) {
  .drawerBtn {
    display: none !important;
  }
  .drawerBtn2 {
    display: flex;
    z-index: 6;
    position: fixed;
    top: 10%;
    left: 7%;
  }
}
