.dialog {
  :global {
    * {
      box-sizing: border-box;
    }
    .MuiPaper-root {
      border-radius: 18px;
      background-color: transparent;
      width: 100%;
      max-width: 492px;
      margin: 15px;
    }
  }

  &.poolResult {
    :global {
      .MuiPaper-root {
        max-width: 540px;
      }
    }
  }

  &.overflow {
    :global {
      .MuiPaper-root {
        overflow: hidden;
      }
    }
  }

  .button {
    border-radius: 100px;
    font-size: 17px;
    font-weight: 700;
    padding: 3px;
    text-transform: none;
    min-height: 44px;
    min-width: 335px;
    font-family: inherit;

    &.claimed {
      &[disabled] {
        background: transparent !important;
        color: #ee367e;
        border: 1px solid #ee367e;
      }
    }

    &[disabled] {
      background: #dee0e8 !important;
      color: #606371;

      &.dark {
        background: #566384 !important;
        color: #ffffff;
      }
    }

    &.autoHeight {
      min-height: auto;
      min-width: 146px;
    }
  }
}

.container {
  width: 100%;
  max-width: 540px;
  word-break: break-all;

  &.overflow {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

.header {
  position: relative;
  background: #1c2230;
  display: flex;
  min-height: 101px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
  flex-direction: column;
  z-index: 3;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: #630e17;
    display: block;
  }
}

.headerBack {
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 2px 6px;
  background: transparent;
  left: 44px;
  outline: none;
  border: 0;
}

.headerTitle {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.headerIcon {
  margin-right: 23px;
  width: 54px;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: transparent;
  outline: none;
  border: 0;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.body {
  background: #ffffff;
}
