.body {
  padding: 18px 31px;
  background-color: #ffffff;
  overflow: auto;
  max-height: 788px;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 21px;
  &:after {
    content: "";
    width: 100%;
    max-width: 288px;
    height: 3px;
    display: block;
    margin-top: 10px;
    background: #630e17;
    border-radius: 3px;
  }
}

.title {
  font-size: 19px;
  line-height: 34px;
  font-weight: 700;
}

.hash {
  font-size: 18px;
  color: inherit;
  text-decoration: underline;
}

.dark {
  .body {
    background-color: #302e2d;
    color: #ffffff;
  }
}
