.dialog {
  :global {
    .MuiPaper-root {
      border-radius: 18px;
      background-color: transparent;
      width: 100%;
      max-width: 492px;
      margin: 15px;
    }
  }
}
.container {
  width: 100%;
  max-width: 492px;
  .header {
    position: relative;
    background: #1C2230;
    display: flex;
    min-height: 78px;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    text-align: center;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    flex-direction: column;

    .headerTitle {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 19px;
      color: #ffffff;
    }
    .logo{
      margin-right: 10px;
      height: 50px;
      width: 38px;

    }

    @media screen and (max-width: 1000px) {
      min-height: 58px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6px;
      background: #630e17;
      display: block;
    }
  }
  .body{
    padding-top: 20px;
    padding-bottom: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #171616;
    color: #FFFFFF;
    .description{
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      .price{
        margin-left: 102px;
        color: #630e17;
      }
    }
  }
  .footer{
    display: flex;
    justify-content: center;
    background-color: #302e2d;
    padding: 37px 0;
  }
}
.close {
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: transparent;
  outline: none;
  border: 0;
  transition: opacity .2s ease-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}
.light {
  .body {
    background-color: #ffffff;

    color: #171616;
  }

  .footer {
    background: #f1f3f9;
    color: #ffffff;
  }
}
