.container {
  background: #f1f3f9;
  display: flex;
  flex-direction: column;
  border-radius: 11px;
  padding: 1rem;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.countdownTitle {
  font-weight: normal;
  font-size: 19px;
  line-height: 28px;

  color: #1c2230;
  margin-bottom: 8px;
  width: 100%;
  display: flex;
}

.countdownBody {
  display: flex;
  align-items: center;
  min-width: 270px;

  &.dark {
    .countdownItem {
      background-color: #3d465b;
      color: #ffffff;
    }
  }
}

.countdownItem {
  background: #e1e5f2;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 6px;
  min-width: 68px;
  min-height: 53px;
  color: #1c2230;
  font-size: 16px;

  span {
    font-size: 26px;
    color: #B4182C;
    padding-right: 4px;
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.noTimerTitle {
  width: 100%;

  margin-bottom: 1rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}

.dark {
  background-color: rgb(29, 34, 47);

  .countdownTitle {
    color: #fff;
  }
  .noTimerTitle {
    color: #ffffff;
  }
}
