.modal {
  :global {
    .MuiPaper-root {
      width: 440px;
      height: 100%;
      background: transparent;
      box-shadow: 0 32px 64px #2425261f;
      * {
        box-sizing: border-box;
      }

      @media screen and (max-width: 767px) {
        width: 335px !important;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #221c32 !important;
  transform: translate(0%, 10%);
  border-radius: 0.5rem;
}

.title {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 12px;
  font-weight: normal;
  line-height: 28px;
  color: #ffffff;
  font-size: 32px;
  font-weight: 500;
  font-family: 'Neuropol';
  letter-spacing: -0.5px;
  @media screen and (max-width: 767px) {
    font-size: 23px !important;
    margin-top: 50px !important;
    margin-bottom: 0.75rem !important;
  }
}

.description {
  font-size: 16px;
  line-height: 23px;
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
  margin-bottom: 40px;
  color: rgb(189, 189, 189);
  opacity: 0.8;
  font-family: 'Lato';
  @media screen and (max-width: 767px) {
    margin-bottom: 24px !important;
    max-width: 281px !important;
  }
}

.list {
  margin: 0 40px 40px 40px;
  padding: 0;
  display: flex;
  gap: 16px;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    margin: 0 20px 20px 20px !important;
  }
}

.item {
  background-color: #ffffff05;
  color: #ffffff;
  list-style: none;
  border-radius: 6px;
  height: 48px;
  padding: 15px 0;
  border: 1.5px solid rgba(255, 255, 255, 0.0823529412);
  transition: all linear 0.25s;
  display: flex;
  padding-left: 34%;
  align-items: center;
  cursor: pointer;
  &.itemDisabled {
    cursor: default;
    pointer-events: none;
  }
  @media screen and (max-width: 767px) {
    padding-left: 30% !important;
  }
  @media screen and (max-width: 400px) {
    padding-left: 27% !important;
  }
  @media screen and (max-width: 360px) {
    padding-left: 24% !important;
  }
}
.item:hover {
  background-color: #f5c17c;
}
.itemInner {
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: normal;
  flex-wrap: wrap;
  display: flex;
  gap: 0.5rem;
}

.logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 100px;
  //   @media screen and (max-width: 1000px) {
  //     margin-right: 15px;
  //     width: 35px;
  //     height: 35px;
  //   }

  img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }

  &.active {
    border-color: #630e17;
  }
}

.arrow {
  margin-left: auto;
  display: none;
}

.disabledText {
  font-size: 17px;
  line-height: 20px;
  margin-left: auto;
}

.back {
  cursor: pointer;
  display: flex;
  border: 0;
  outline: none;
  background: transparent;
  position: absolute;
  left: 14px;
  top: 14px;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: transparent;
  outline: none;
  border: 0;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
  @media screen and (max-width: 767px) {
    top: 20px !important;
    right: 10px !important;
  }
}

.loading {
  text-align: center;
  height: 100px;
  margin-top: 100px;

  //   @media screen and (max-width: 1000px) {
  //     height: 100px;
  //   }
}

.loading img {
  width: 50px;
  height: 50px;
}

.footer {
  margin-top: auto;
  border-top: 1px solid rgba(164, 169, 184, 0.29);
  background: inherit;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  color: #606371;
  padding: 14px 0;

  a {
    color: #B4182C;
    text-decoration: none;
  }
}
