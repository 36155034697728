.dialog {
  :global {
    .MuiDialog-container {
      align-items: flex-start;
      margin-top: 100px;

      @media screen and (max-width: 1000px) {
        align-items: center;
        margin-top: 0;
      }
    }

    .MuiPaper-root {
      border-radius: 18px;
      background-color: transparent;
      width: 100%;
      max-width: 650px;
      margin: 15px;
      max-height: calc(100% - 164px);

      @media screen and (max-width: 1000px) {
        max-height: calc(100% - 64px);
      }
    }
  }

  p {
    margin: 0;
  }
}

.container {
  position: relative;
}

.dark {
  .body {
    background-color: #10131c;
  }

  .footer, .balance {
    background-color: #282626;
  }

  .body, .footer, .balance {
    color: #fff;
  }

}

.header {
  position: relative;
  background: #1C2230;
  display: flex;
  z-index: 3;
  min-height: 56px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    min-height: 58px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: #630e17;
    display: block;
  }
}

.headerTitle {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #ffffff;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: transparent;
  outline: none;
  border: 0;
  transition: opacity .2s ease-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.body {
  background: #fff;
  padding: 20px 30px 0 30px;

  @media screen and (max-width: 1000px) {
    padding: 14px;
  }

  .token {
    margin-bottom: 35px;
    justify-content: center;
  }
}

.inputWrapper {
  width: 320px;
  max-width: 100%;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}

.input {
  margin-bottom: 15px;
}

.balance {
  margin: 0 auto 35px auto;
}

.tabWrapper {
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  justify-content: space-between;
}

.tab {
  background-color: transparent;
  border: 0;
  color: #7F89A7;
  font-weight: 600;
  font-size: 21px;
  line-height: 160%;
  width: 192px;
  outline: 0;
  cursor: pointer;
  padding-bottom: 5px;
  padding-top: 0;
}

.tabActive {
  color: #630e17;
  box-shadow: 0px 4px 0 0px #B4182C;
}

.footer {
  background: #F1F3F9;
  border-radius: 0 0 18px 18px;
  padding: 25px 30px;
}

.labelWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrowImg {
  width: 23px;
  margin-left: 9px;
  margin-right: 9px;
}

.lineValue {
  font-size: 17px;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
}

.error {
  text-align: center;
  margin-bottom: 16px;
  margin-top: -10px;
  color: #630e17;
}
