h1 {
  font-family: Neuropol;
  letter-spacing: -.5px;
  font-weight: 500
}

@font-face {
  font-family: Neuropol;
  src: url(/assets/fonts/neuropol.880e0ed5.ttf) format("truetype")
}

@font-face {
  font-family: Raleway;
  src: url(/assets/fonts/Raleway-VariableFont_wght.de7f7751.ttf) format("truetype")
}

@font-face {
  font-family: Lato;
  src: url(/assets/fonts/Lato-Regular.ttf) format("truetype")
}

.burgerButton {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
}

.mobileHeader {
  display: none;

  @media screen and (max-width: 993px) {
    position: absolute;
    display: block;
    z-index: 1;
    top: 0.5rem;
    right: 0.5rem;
  }
}

.mobileHeaderButton {
  margin-left: auto;
}

.MuiButton-containedSecondary {
  background: #B4182C !important;
}

.MuiButton-containedSecondary:hover {
  background: linear-gradient(
    to bottom,
    #fb0f6b 30.26%,
    #b63d98 115.29%,
    #b53d99 116.78%,
    #547ed8 117.99%,
    #8e57b2 119.77%
  ) !important;
}

/* transition on btn will cause blank*/

.MuiButton-root {
  transition: none !important;
  text-transform: none;
}

input,
button {
  font-family: 'Lato';
}

* {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #555;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #777778;
  }
  scrollbar-color: #777778 transparent;
  scrollbar-width: thin;
}

body {
  background-color: #090915;
  margin: 0;
  line-height: unset !important;
  font-family: 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1320px) {
  body.mobileOverflow {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.rotate {
  width: 120px;
  height: 120px;
  margin-top: -100px;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.rotate.noMT {
  margin-top: 0;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.imgPulse {
  margin: 10px;
  height: 40px;
  width: 40px;
  transform: scale(1);
  animation: pulse 2s infinite;
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(0.95);
  }

  70% {
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(0.95);
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
  }

  70% {
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(0.95);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.95);
  }
}

.theme-dark .help-title {
  color: #fff;
}
.theme-dark .empty-result {
  color: #fff;
}
.theme-dark .search-input {
  color: #fff;
  background: #515f7f;
  border: none;
}
.theme-dark .search-input:active,
.theme-dark .search-input:hover,
.theme-dark .search-input:focus {
  border: none;
  box-shadow: none;
}
.theme-dark .search-input .ant-input {
  color: #fff;
  background: #515f7f;
}
.theme-dark .faq-questions {
  color: #fff;
}
.theme-dark .answer-board {
  color: #fff;
  background: #171616;
}
.theme-dark .feedback-wrapper .feedback {
  color: #fff;
}
.theme-light .help-title {
  color: #464646;
}
.theme-light .search-input {
  border: 1px solid #d2d6e8;
}
.theme-light .search-input:active,
.theme-light .search-input:hover,
.theme-light .search-input:focus {
  border: 1px solid #d2d6e8;
  box-shadow: none;
}
.theme-light .answer-board {
  border: 1px solid #d2d6e8;
  color: #464646;
  background: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
