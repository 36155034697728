.connectButton {
  &.connectButtonHelpClass {
    background-color: #5a1727;
    box-sizing: border-box;
    border-radius: 6px;
    width: 194px;
    height: 48px;
    font-size: 15px;
    color: #ffffff;
    text-transform: none;
    font-weight: 500;
    overflow: hidden;
    transition: all 0.2s ease-out !important;
    position: relative;

    .connectButtonBody {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
    }

    svg {
      margin-left: 8px;

      path {
        transition: all 0.2s ease-out;
      }
    }

    &:hover {
      color: #ffffff;
      svg path {
        stroke: #ffffff;
      }
      &:after {
        opacity: 1;
      }
    }

    &:active {
      opacity: 0.7;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.2s ease-out;
      opacity: 0;
      background-color: #744852;
    }
  }
}
