.dialog {
  :global {
    .MuiDialog-container {
      align-items: flex-start;
      margin-top: 100px;

      @media screen and (max-width: 1000px) {
        align-items: center;
        margin-top: 0;
      }
    }

    .MuiPaper-root {
      background-color: transparent;
      width: 100%;
      max-width: 510px;
      margin: 15px;
      max-height: calc(100% - 164px);

      @media screen and (max-width: 1000px) {
        max-height: calc(100% - 64px);
      }
    }
  }

  p {
    margin: 0;
  }
}

.container {
  position: relative;
}

.dark {
  .body {
    background-color: #10131c;

    color: #fff;
  }

  .footer {
    background-color: #282626;
    color: #ffffff;
  }
}

.header {
  position: relative;
  background: #1c2230;
  display: flex;
  min-height: 56px;
  z-index: 3;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  text-align: center;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    min-height: 58px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: #630e17;
    display: block;
  }
}

.headerTitle {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #ffffff;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: transparent;
  outline: none;
  border: 0;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.body {
  background: #fff;
  padding: 20px 40px;
  text-align: center;
  @media screen and (max-width: 1000px) {
    padding: 14px;
  }
}

.text {
  font-size: 19px;
  line-height: 150%;
}

.link {
  text-decoration: none;
  color: #630e17;
}

.footer {
  padding: 12px 33px 18px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 13px 13px;
  background: #f1f3f9;

  .cardFooterControlsButton {
    border-radius: 100px;

    span {
      font-size: 19px;
      font-weight: bold;
      text-transform: initial;
      color: #fdfdfd;
    }
  }
}

.cardFooterControlsButton {
  width: 100%;
  max-width: 295px;
  min-height: 44px;
  padding: 5px 16px;
  text-transform: none;
  font-weight: 700;

  &:not(:last-child) {
    margin-bottom: 27px;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
}

.error {
  text-align: center;
  margin-bottom: 16px;
  color: #630e17;
}

.description {
  font-size: 19px;
  font-weight: 600;
  font-style: italic;
  line-height: 28px;
  margin-bottom: 18px;
}
