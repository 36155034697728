.wrapper {
  position: relative;
  z-index: 1;
  padding: 0 25px 0 93px;

  @media screen and (max-width: 1000px) {
    padding: 0 15px;
  }

  .downloadLinks {
    margin-left: auto;

    @media screen and (max-width: 1000px) {
      margin-left: 0;
      margin-top: 10px;
    }

    .downloadButton {
      img {
        margin-left: 5px;
        height: 36px;
      }
    }
  }
}

.leftBlock {
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 10px 0;
    height: auto;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.footerMain {
  width: 100%;
}
.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #221c32bf;
}
.footerBox {
  width: 100%;
  padding-bottom: 2.25rem;
  padding-top: 3rem;
  padding-left: 40px;
  padding-right: 40px;
}
.footerItems {
  display: flex;
  flex-direction: row;
  gap: 150px;
  justify-content: center;
}
.copyrightText {
  font-size: 11px;
  font-weight: 400;
  color: rgb(148, 148, 148);
}
.hackenTextIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.hackenText {
  margin-right: 10px;
  margin-top: -5px;
  font-size: 11px;
  font-weight: 400;
  text-decoration: underline;
  color: #ffffff;
}
.iconSocialText {
  display: flex;
  align-items: center;
}
.iconSocialText2 {
  display: flex;
  align-items: center;
}
.footerSocialText {
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  height: 36px;
  width: auto;
  display: flex;
  align-items: center;
}
.footerSocialText2 {
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  height: 36px;
  width: auto;
  display: flex;
  align-items: center;
}
.socialIcons {
  margin-right: 10px;
}
.socialIconsMedium {
  margin-right: 5px;
  margin-left: -2px;
  margin-top: 5px;
}
.itemsBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 150px;
}
@media screen and (max-width: 1324px) {
  .footerItems {
    gap: 100px !important;
  }
  .itemsBox {
    gap: 100px !important;
  }
}
@media screen and (max-width: 1124px) {
  .footerItems {
    gap: 50px !important;
  }
  .itemsBox {
    gap: 50px !important;
  }
}
@media screen and (max-width: 924px) {
  .footerItems {
    gap: 10px !important;
  }
  .itemsBox {
    gap: 10px !important;
  }
}
@media screen and (max-width: 767px) {
  .footerSocialText {
    display: none !important;
  }
  .itemsBox {
    gap: 15px !important;
    align-items: baseline !important;
    margin-top: 5px !important;
  }
  .footerItems {
    gap: 40px !important;
    justify-content: space-between !important;
  }
  .iconSocialText {
    margin-bottom: 15px !important;
  }
  .footerSocialText2 {
    font-size: 8px !important;
  }
  .copyrightText {
    font-size: 8px !important;
  }
  .hackenText {
    font-size: 8px !important;
  }
  .footerBox {
    padding: 1.25rem !important;
  }
}
@media screen and (max-width: 511px) {
  .footerItems {
    gap: 10px !important;
  }
  .itemsBox {
    gap: 10px !important;
  }
}
@media screen and (max-width: 441px) {
  .footerBox {
    padding: 1rem !important;
  }
  .footerItems {
    gap: 5px !important;
  }
  .itemsBox {
    gap: 5px !important;
  }
}
